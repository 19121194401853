import { AllowedFileExtensions } from '@src/constants/index.js'
import isMobilePhone from 'validator/lib/isMobilePhone'
import isSameDay from 'date-fns/isSameDay'
import isAfter from 'date-fns/isAfter'
import isValid from 'date-fns/isValid'

export const validateError = (field, validators, errMsg) => {
  if (!field.$dirty) return []

  const index = validators.findIndex((validator) => !field[validator])

  if (index === -1) {
    return []
  }
  return Array.isArray(errMsg) ? [errMsg[index]] : [errMsg]
}

export const mobilePhoneValidator = (value, vm) => {
  return isMobilePhone(value)
}

export const isSameOrAfter = (fromDate) => (toDate, vm) => {
  const from = new Date(fromDate)
  const to = new Date(toDate)

  if (!isValid(from) || !isValid(to)) return false

  if (isSameDay(from, to) || isAfter(to, from)) return true
  return false
}

export const validExtension = (file) => {
  if (!file) return true
  const fileName = file && file.name
  const extension = (fileName && fileName.split('.').pop()) || ''
  return AllowedFileExtensions.includes(extension)
}
